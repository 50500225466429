// Essential Imports
import React, { createContext, useState, useEffect } from "react"

import { stripesService } from "../api/services"

export const AccountContext = createContext()

export const AccountProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [accountInfo, setAccountInfo] = useState({
    subscriptionPlan: null,
    subscriptionStart: null,
    subscriptionEnd: null,
    maxSource: null,
    maxQueries: null,
    maxStorage: null,
    currentQueries: null,
    currentSource: null,
    currentStorage: null,
    fragmentEdit: false,
    editSource: false, // no such feature yet
    allowModels: [],
    allowFunctions: [],
    allowRequests: null,
    maxReference: [],
    functionLimit: [],
    organizations: [],
  })
  const [subPrompt, setSubPrompt] = useState(false)
  const [secret, setSecret] = useState("")
  const [isFreePlan, setIsFreePlan] = useState(false)
  const [isStarterPlan, setIsStarterPlan] = useState(false)
  const [isProPlan, setIsProPlan] = useState(false)

  // Handle subscription upgrade prompt
  useEffect(() => {
    if (!subPrompt) return

    stripesService
      .getUserSession()
      .then((response) => {
        setSecret(response.data.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [subPrompt])

  // Helper function to make fetch requests and handle 401 status.
  const fetchAccountStatus = async (callback) => {
    try {
      const { data } = await stripesService.getUserSubscription()
      const newAccountState = {
        ...accountInfo,
        subscriptionPlan: data.data.subscription.name,
        subscriptionPlanRaw: data.data.subscription.name.toLowerCase(),
        subscriptionStart: data.data.start_time,
        subscriptionEnd: data.data.stop_time,
        currentCredits: data.data.current_credit,
        currentQueries: data.data.current_query,
        currentSource: data.data.current_data_source,
        currentStorage: data.data.current_storage_gb_usage,
        maxCredits: data.data.subscription.credit_limit,
        maxQueries: data.data.subscription.query_limit,
        maxSource: data.data.subscription.data_source_limit,
        maxStorage: data.data.subscription.storage_gb_limit,
        fragmentEdit: data.data.subscription.is_allow_edit_fragments,
        allowModels: data.data.subscription.llm_models,
        allowFunctions: data.data.subscription.vext_functions,
        allowRequests: data.data.subscription.is_allow_requests,
        maxReference: data.data.subscription.refer_limit,
        functionLimit: data.data.subscription.smartfunctions_limit,
        freshnessLimit: data.data.subscription.datafreshness_limit,
        organizations: data.data.orgs,
        personalOrganization: data.data?.orgs.find((org) => org.type === "p"),
      }

      setAccountInfo(newAccountState)
      if (callback) callback(newAccountState)
    } catch (error) {
      if (error.response?.status === 401) {
        setIsAuthenticated(false)
        document.cookie = "userLoggedIn=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
        // throw new Error("Unauthenticated")
      }
      return error
    }
  }

  useEffect(() => {
    setIsFreePlan(accountInfo.subscriptionPlanRaw === "free")
    setIsStarterPlan(accountInfo.subscriptionPlanRaw === "starter")
    setIsProPlan(accountInfo.subscriptionPlanRaw === "pro")
  }, [accountInfo])

  return (
    <AccountContext.Provider
      value={{
        accountInfo,
        setAccountInfo,
        fetchAccountStatus,
        isAuthenticated,
        setIsAuthenticated,
        subPrompt,
        setSubPrompt,
        secret,
        isFreePlan,
        isStarterPlan,
        isProPlan,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}
