import React, { useContext, useEffect, useState } from "react"
import { Button, FormControlLabel, RadioGroup, Radio, Stack, Skeleton } from "@mui/material"
import { Refresh as RefreshIcon } from "@mui/icons-material"

import BasicTooltip from "../../items/BasicTooltip"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import SelectField from "../../items/SelectField"
import TextArea from "../../items/TextArea"
import MixedTextArea from "../../items/MixedTextArea"
import ModelSection from "../../children/ModelSection"
import ModelParamsSection from "../../children/ModelParamsSection"
import { AccountContext } from "../../../helper/AccountContext"
import { DEFAULT_TEMPERATURE, DEFAULT_TOP_K, DEFAULT_TOP_P, MODELS, SYSTEM_PROMPT } from "../../../utils/constants"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const PROVIDERS = [
  { value: "bedrock", label: "AWS Bedrock (Coming soon)", disabled: true },
  { value: "sage-maker", label: "AWS SageMaker", disabled: false },
  { value: "azure", label: "Azure OpenAI (Coming soon)", disabled: true },
  { value: "openai", label: "OpenAI (Coming soon)", disabled: true },
]

const RadioLabel = (props) => (
  <FormControlLabel sx={{ mr: 5 }} control={<Radio sx={{ p: 0, mr: 1.5 }} />} size="small" {...props} />
)

const ConfigDrawer = ({ availableVars, formData, sequence, onSubmit, isLoadingForm, setUnsaved }) => {
  const [allowModels, setAllowModels] = useState([])
  const [awsConfig, setAwsConfig] = useState({
    access_key: "",
    secret_key: "",
    boto_region: "",
    endpoint_region: "",
    endpoint_name: "",
  })
  const [enableTemperature, setEnableTemperature] = useState(false)
  const [enableTopP, setEnableTopP] = useState(false)
  const [enableTopK, setEnableTopK] = useState(false)
  const [modelKwargs, setModelKwargs] = useState("")
  const [isEmptySystemPrompt, setIsEmptySystemPrompt] = useState(true)
  const [showTopK, setShowTopK] = useState(false)
  const [initialSystemPrompt, setInitialSystemPromptInput] = useState("")
  const [systemPromptInput, setSystemPromptInput] = useState("")
  const [llmType, setLlmType] = useState("mllm")
  const [temperatureInput, setTemperatureInput] = useState(DEFAULT_TEMPERATURE)
  const [topPInput, setTopPInput] = useState(DEFAULT_TOP_P)
  const [topKInput, setTopKInput] = useState(DEFAULT_TOP_K)
  const [modelInput, setModelInput] = useState("")
  const [modelType, setModelType] = useState("")
  const [maxToken, setMaxToken] = useState("")
  const [modelId, setModelId] = useState("")
  const [providerInput, setProviderInput] = useState("")
  const [isDatachanged, setIsDataChanged] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const { fetchAccountStatus, setSubPrompt } = useContext(AccountContext)

  const handleChangeParams = (type, value) => {
    switch (type) {
      case "temperature":
        setTemperatureInput(value)
        break
      case "topP":
        setTopPInput(value)
        break
      case "topK":
        setTopKInput(value)
        break
    }
  }
  const handleChangeSwitch = (type, value) => {
    switch (type) {
      case "temperature":
        setEnableTemperature(value)
        break
      case "topP":
        setEnableTopP(value)
        break
      case "topK":
        setEnableTopK(value)
        break
    }
  }
  const handleOnBlur = (value) => {
    setSystemPromptInput(value)
  }
  const handleOnChange = (value) => {
    setIsEmptySystemPrompt(!value)
    const isChanged = value !== initialSystemPrompt
    setIsDataChanged(isChanged)
  }
  const handleOnSubmit = () => {
    if (isDatachanged) {
      let payload = { configId: formData.id, componentId: formData.componentId, systemPrompt: systemPromptInput }

      if (llmType === "mllm") {
        payload = {
          ...payload,
          is_custom_config: false,
          model: modelInput,
          temperature: temperatureInput,
          top_p: topPInput,
          top_k: topKInput,
        }
      } else if (llmType === "byollm") {
        payload = {
          ...payload,
          is_custom_config: true,
          aws_content: awsConfig,
          model_kwargs: modelKwargs,
        }
      }

      onSubmit(payload)
    } else {
      onSubmit()
    }
  }
  const handleChangeModel = (event) => {
    const targetModel = MODELS.find((model) => model.value === event?.target.value) || {}
    const isChanged = targetModel.value !== modelInput

    setIsDataChanged(isChanged)
    if (targetModel.value) {
      if (allowModels.includes(targetModel.value)) {
        setModelInput(targetModel.value)
        setModelType(targetModel.type)
        setMaxToken(targetModel.max)
        setModelId(targetModel.system_ui || targetModel.system)
      } else {
        setSubPrompt(true)
      }
    }
  }
  const handleChangeLlmType = (event) => {
    setLlmType(event.target.value)
  }
  const handleChangeProvider = (event) => {
    setProviderInput(event.target.value)
  }
  const handleChangeAwsConfig = (value) => {
    setAwsConfig({ ...awsConfig, ...value })
  }

  const loadDefaultPrompt = async () => {
    if (!modelInput || !modelType) {
      console.error("Model type is not selected")
      return
    }

    const typeKey = modelType === "os" ? "os_rag" : modelType === "cs" ? "cs_rag" : "llama3_rag"
    const url = SYSTEM_PROMPT[typeKey].file

    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error("Failed to fetch the default prompt")
      }
      const fileText = await response.text()
      const text = fileText.replace(/\r/g, "")

      setSystemPromptInput(text)
    } catch (error) {
      console.error("Error fetching default prompt:", error)
    }
  }

  useEffect(() => {
    if (allowModels?.length) {
      setIsLoading(false)
    }
  }, [allowModels])

  useEffect(() => {
    setIsDisabled(true)
    if (llmType === "mllm") {
      if (modelInput && !isEmptySystemPrompt && temperatureInput >= 0 && topPInput >= 0 && topKInput >= 0) {
        setIsDisabled(false)
      }
    } else if (llmType === "byollm") {
      const hasEmptyField = Object.values(awsConfig).some((item) => !item)
      if (providerInput && modelKwargs && !isEmptySystemPrompt && !hasEmptyField) {
        setIsDisabled(false)
      }
    }
  }, [llmType])

  useEffect(() => {
    if (llmType === "mllm") {
      const hasEmptyField = !modelInput || temperatureInput < 0 || topPInput < 0 || topKInput < 0 || isEmptySystemPrompt

      setIsDisabled(hasEmptyField)
      if (formData.id) {
        const modelValue = MODELS.find((model) => model.system === formData.model)?.value
        const isChanged = modelValue !== modelInput

        setIsDataChanged(isChanged)
      }
      if (!modelInput || modelInput.indexOf("gpt-") >= 0 || modelInput.indexOf("llama3-") === 0) {
        setShowTopK(false)
      } else {
        setShowTopK(true)
      }
    }
  }, [modelInput, isEmptySystemPrompt])

  useEffect(() => {
    const newTemperature = enableTemperature ? temperatureInput ?? DEFAULT_TEMPERATURE : null
    const isChanged =
      (!enableTemperature && formData.temperature) || (enableTemperature && formData.temperature !== temperatureInput)

    setTemperatureInput(newTemperature)
    setIsDataChanged(isChanged)
  }, [enableTemperature, temperatureInput])

  useEffect(() => {
    const newTopP = enableTopP ? topPInput ?? DEFAULT_TOP_P : null
    const isChanged = (!enableTopP && formData.top_p) || (enableTopP && formData.top_p !== topPInput)

    setTopPInput(newTopP)
    setIsDataChanged(isChanged)
  }, [enableTopP, topPInput])

  useEffect(() => {
    const newTopK = enableTopK ? topKInput ?? DEFAULT_TOP_K : null
    const isChanged = (!enableTopK && formData.top_k) || (enableTopK && formData.top_k !== topKInput)

    setTopKInput(newTopK)
    setIsDataChanged(isChanged)
  }, [enableTopK, topKInput])

  useEffect(() => {
    if (llmType === "byollm") {
      const hasEmptyField = Object.values(awsConfig).some((item) => !item)

      setIsDisabled(!modelKwargs || isEmptySystemPrompt || hasEmptyField)
      if (formData.id) {
        const kwargsString = JSON.stringify(formData.custom_config_parameter)
        const isCustomParamsChanged = !formData.masked_aws_content
          ? true
          : Object.keys(formData.masked_aws_content).some((key) => formData.masked_aws_content[key] !== awsConfig[key])
        const isChanged = isCustomParamsChanged || kwargsString !== modelKwargs || !isEmptySystemPrompt

        setIsDataChanged(isChanged)
      }
    }
  }, [awsConfig, modelKwargs, isEmptySystemPrompt])

  useEffect(() => {
    if (formData.id) {
      if (formData.is_custom_config) {
        setLlmType("byollm")
        setProviderInput("sage-maker")
        setAwsConfig(formData.masked_aws_content)
        setModelKwargs(JSON.stringify(formData.custom_config_parameter))
      } else {
        const targetModel = MODELS.find((model) => model.system === formData.model) || {}

        setLlmType("mllm")
        if (formData.temperature !== null) {
          setEnableTemperature(true)
          setTemperatureInput(formData.temperature)
        } else {
          setEnableTemperature(false)
        }
        if (formData.top_p !== null) {
          setEnableTopP(true)
          setTopPInput(formData.top_p)
        } else {
          setEnableTopP(false)
        }
        if (formData.top_k !== null) {
          setEnableTopK(true)
          setTopKInput(formData.top_k)
        } else {
          setEnableTopK(false)
        }
        setModelInput(targetModel.value)
        setModelType(targetModel.type)
        setMaxToken(targetModel.max)
        setModelId(targetModel.system_ui || targetModel.system)
      }
      setSystemPromptInput(formData.systemPrompt)
      setInitialSystemPromptInput(formData.systemPrompt)
    }
  }, [formData])

  useEffect(() => {
    setUnsaved(isDatachanged)
  }, [isDatachanged])

  useEffect(() => {
    fetchAccountStatus((account) => {
      setAllowModels(account.allowModels)
    })
  }, [])

  return (
    <>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoading || isLoadingForm} />
      </section>
      {isLoading ? (
        <Stack spacing={2} sx={{ padding: "1.5rem 2rem" }}>
          <Skeleton variant="rounded" animation="wave" height={80} />
          <Skeleton variant="rounded" animation="wave" height={70} />
          <Skeleton variant="rounded" animation="wave" height={65} />
          <Skeleton variant="rounded" animation="wave" height={400} />
        </Stack>
      ) : (
        <>
          <Button
            sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
            onClick={handleOnSubmit}
            variant="contained"
            disabled={isDisabled || isLoadingForm || !isDatachanged}
          >
            Save
          </Button>
          <div className={ActionDrawerStyles.main}>
            <div>
              <h4>LLM Type</h4>
              <div style={{ margin: "12px 8px 0" }}>
                <RadioGroup
                  row
                  aria-labelledby="llmtype-btn-group"
                  name="llmtype-btn-group"
                  value={llmType}
                  onChange={handleChangeLlmType}
                >
                  <RadioLabel value="mllm" label="Managed LLM" />
                  <RadioLabel value="byollm" label="Bring Your Own LLM" />
                </RadioGroup>
              </div>
            </div>
            {llmType === "mllm" && (
              <>
                <ModelSection
                  isLoadingForm={isLoading}
                  modelInput={modelInput}
                  modelId={modelId}
                  maxToken={maxToken}
                  onChangeModel={handleChangeModel}
                />
                {modelInput && (
                  <ModelParamsSection
                    enableTemperature={enableTemperature}
                    enableTopK={enableTopK}
                    enableTopP={enableTopP}
                    temperature={temperatureInput}
                    topP={topPInput}
                    topK={topKInput}
                    showTopK={showTopK}
                    onChangeSwitch={handleChangeSwitch}
                    onChangeValue={handleChangeParams}
                  />
                )}
              </>
            )}
            {llmType === "byollm" && (
              <>
                <div>
                  <div className={ActionDrawerStyles.flex}>
                    <h4>Provider</h4>
                    <SelectField options={PROVIDERS} value={providerInput} onChange={handleChangeProvider} />
                  </div>
                  {providerInput === "sage-maker" && (
                    <>
                      <div className={ActionDrawerStyles.inputContainer}>
                        <label>AWS Access Key ID</label>
                        <TextArea
                          onChange={(value) => handleChangeAwsConfig({ access_key: value })}
                          value={awsConfig.access_key}
                          mLength={100}
                        />
                      </div>
                      <div className={ActionDrawerStyles.inputContainer}>
                        <label>AWS Secret Access Key</label>
                        <TextArea
                          onChange={(value) => handleChangeAwsConfig({ secret_key: value })}
                          value={awsConfig.secret_key}
                          mLength={100}
                        />
                      </div>
                      <div className={ActionDrawerStyles.inputContainer}>
                        <label>AWS Profile Region Name</label>
                        <TextArea
                          onChange={(value) => handleChangeAwsConfig({ boto_region: value })}
                          value={awsConfig.boto_region}
                          mLength={50}
                        />
                      </div>
                      <div className={ActionDrawerStyles.inputContainer}>
                        <label>SageMaker Region Name</label>
                        <TextArea
                          onChange={(value) => handleChangeAwsConfig({ endpoint_region: value })}
                          value={awsConfig.endpoint_region}
                          mLength={50}
                        />
                      </div>
                      <div className={ActionDrawerStyles.inputContainer}>
                        <label>Endpoint</label>
                        <TextArea
                          onChange={(value) => handleChangeAwsConfig({ endpoint_name: value })}
                          value={awsConfig.endpoint_name}
                          mLength={150}
                        />
                      </div>
                    </>
                  )}
                </div>
                {providerInput === "sage-maker" && (
                  <div>
                    <h4 style={{ marginBottom: 12 }}>
                      Model Kwargs
                      <BasicTooltip tooltip="Set 'temperature', 'max tokens', and other model-specific parameters in JSON format." />
                    </h4>
                    <TextArea
                      placeholder={`e.g. {"do_sample": True, "top_p": 0.3, "max_tokens": 1024, "temperature": 0.5}`}
                      onChange={setModelKwargs}
                      value={modelKwargs}
                      mLength={1000}
                      rows={6}
                      inputComponent="textarea"
                    />
                  </div>
                )}
              </>
            )}
            {(modelInput || llmType === "byollm") && (
              <>
                <div>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                    <h4>
                      System Prompt
                      <BasicTooltip
                        tooltip={
                          <span>
                            {`Enter your template and variables here. To see some of the common default system prompt template, `}
                            <a
                              style={{ color: "#fff" }}
                              href="https://help.vextapp.com/en/articles/9248820-guide-to-llm-system-prompt"
                              target="blank"
                            >
                              see here.
                            </a>
                          </span>
                        }
                      />
                    </h4>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "0.2rem", cursor: "pointer" }}
                      onClick={loadDefaultPrompt}
                    >
                      <RefreshIcon sx={{ fontSize: "0.9rem" }} />
                      <p style={{ fontSize: "0.7rem" }}>Load Default</p>
                    </div>
                  </div>
                  <div style={{ marginTop: 12 }}>
                    <MixedTextArea
                      className="row-15"
                      initialValue={systemPromptInput}
                      chipList={availableVars}
                      onBlur={handleOnBlur}
                      onChange={handleOnChange}
                      mLength={2000}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <h4>
                    Output Variable
                    <BasicTooltip
                      tooltip={`The result generated from this action can be referred as the following variable in any future actions.`}
                    />
                  </h4>
                  <p>{`action_${sequence}_output`}</p>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default ConfigDrawer
