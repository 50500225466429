import React, { useContext, useState } from "react"
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "reactflow"
import { Box, Button, Paper, IconButton } from "@mui/material"
import { Add as AddIcon } from "@mui/icons-material"
import { ClickAwayListener } from "@mui/base"

import { AccountContext } from "../../helper/AccountContext"
import Chips from "./Chips"

import {
  NODE_CODE,
  NODE_CONFIG,
  NODE_DATASET,
  NODE_FUNCTION,
  NODE_SMART_FUNCTION,
  NODE_HTTP_REQUEST,
} from "../template/actionflow.static"

const ACTION_TYPES = [NODE_CONFIG, NODE_SMART_FUNCTION, NODE_DATASET, NODE_FUNCTION, NODE_CODE, NODE_HTTP_REQUEST]

const ActionDialog = ({ onClickItem }) => (
  <Paper sx={{ left: 50, position: "absolute", top: 20, width: 216, padding: "1rem" }}>
    <h3 style={{ fontSize: "1rem", paddingBottom: "0.5rem" }}>Add an Action</h3>
    {ACTION_TYPES.map((action) => (
      <Button
        key={action.data.name}
        value={action.data.name}
        color="primary"
        sx={{ display: "flex", gap: "8px", justifyContent: "start", textTransform: "none", width: "100%" }}
        onClick={(event) => onClickItem(event, action.data.name)}
      >
        <action.data.icon sx={{ border: "1px solid #3d3d3d30", borderRadius: "4px", fontSize: 32, padding: "2px" }} />
        <span style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>{action.data.label}</span>
        {["code", "sf"].includes(action.data.name) && <Chips status="beta" />}
        {action.data.name === "req" && <Chips status="pro" />}
      </Button>
    ))}
  </Paper>
)
export default function CustomEdge({ id, data, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }) {
  const { accountInfo, setSubPrompt } = useContext(AccountContext)
  const { onClickDialogButton, onClickEdgeButton } = data
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [edgePath, labelX] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  const closeActionDialog = () => {
    setVisibleDialog(false)
  }
  const openActionDialog = (event, id) => {
    if (!visibleDialog) {
      onClickEdgeButton(id)
    }
    setVisibleDialog(!visibleDialog)
  }
  const onClickDialogItem = (event, componentName) => {
    if (componentName === "req" && !accountInfo.allowRequests) {
      setSubPrompt(true)
    } else {
      event.preventDefault()
      onClickDialogButton(event)
      closeActionDialog()
    }
  }

  return (
    <>
      <BaseEdge path={edgePath} />
      <EdgeLabelRenderer>
        <div
          id="addActionBtn"
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${(sourceY + targetY) / 2}px)`,
            pointerEvents: "all",
            zIndex: 1,
          }}
        >
          <ClickAwayListener onClickAway={closeActionDialog}>
            <Box>
              {visibleDialog ? <ActionDialog onClickItem={(event, name) => onClickDialogItem(event, name)} /> : null}
              <IconButton
                sx={{ backgroundColor: "#fff", border: "1px solid #3d3d3d20" }}
                onClick={(event) => openActionDialog(event, id)}
                onMouseDown={(event) => {
                  // to prevent hanging ripple effect of MUI button
                  event.stopPropagation()
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </ClickAwayListener>
        </div>
      </EdgeLabelRenderer>
    </>
  )
}
