import React, { useEffect, useState, useContext } from "react"
import { Button, IconButton, TextField } from "@mui/material"
import { Delete as DeleteIcon } from "@mui/icons-material"

import { ErrorContext } from "../../../helper/AlertContext"
import BasicTooltip from "../../items/BasicTooltip"
import LocalLoadingBar from "../../items/LocalLoadingBar"
import MixedTextArea from "../../items/MixedTextArea"
import SelectField from "../../items/SelectField"
import { arrayToObject, objectToArray } from "../../../utils"

import ActionDrawerStyles from "./ActionDrawer.module.css"

const BODY_TYPES = [
  { label: "None", value: "none" },
  { label: "JSON", value: "json" },
  { label: "Form Data", value: "form" },
]
const REQUEST_METHODS = [
  { label: "GET", value: "get" },
  { label: "POST", value: "post" },
  { label: "HEAD", value: "head" },
  { label: "PATCH", value: "patch" },
  { label: "PUT", value: "put" },
  { label: "DELETE", value: "delete" },
]

const ParamColumn = ({ availableVars, column, index, onKeyChange, onValueChange, onDelete }) => {
  return (
    <div className={ActionDrawerStyles.column}>
      <div>
        <h5>Key</h5>
        <TextField
          value={column.key}
          inputProps={{ maxLength: 50 }}
          placeholder={`Key ${index + 1}`}
          onChange={(event) => onKeyChange(index, event.target.value)}
        />
      </div>
      <div>
        <h5>Value</h5>
        <div>
          <MixedTextArea
            chipList={availableVars}
            initialValue={column.value}
            onBlur={(value) => {
              onValueChange(index, value)
            }}
            mLength={200}
            placeholder={`Value ${index + 1}`}
            hideEndAdornment
            hideVariables
          />
          <IconButton onClick={() => onDelete(index)}>
            <DeleteIcon style={{ fontSize: 16 }} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

const HttpRequestDrawer = ({ availableVars, formData, isLoadingForm, sequence, onSubmit = () => {} }) => {
  const { setError, setErrorMsg } = useContext(ErrorContext)
  const [apiInput, setApiInput] = useState({
    method: "",
    url: "",
  })
  const [bodyType, setBodyType] = useState("")
  const [codeInput, setCodeInput] = useState("")
  const [bodyInputs, setBodyInputs] = useState([{ key: "", value: "" }])
  const [headers, setHeaders] = useState([{ key: "", value: "" }])
  const [params, setParams] = useState([{ key: "", value: "" }])
  const [isDisabled, setIsDisabled] = useState(true)

  const isValidKey = (key) => {
    return /^[A-Za-z0-9_]+$/.test(key)
  }

  const variableAction = {
    add: (stateUpdater) => () => {
      stateUpdater((prevValue) => [...prevValue, { key: "", value: "" }])
    },
    edit: (stateUpdater, index, input) => {
      stateUpdater((prevValue) =>
        prevValue.map((item, idx) => {
          if (idx === index) return { ...item, ...input }
          return item
        }),
      )
    },
    remove: (stateUpdater) => (index) => {
      stateUpdater((prevState) => prevState.filter((_, i) => i !== index))
    },
  }
  const handleOnSubmit = () => {
    let isEmpty = false

    if (isEmpty) {
      setError(true)
      setErrorMsg("Input cannot be empty.")
    } else {
      const payload = { componentId: formData.componentId, requestId: formData.id, requests: {} }

      payload.url = apiInput.url
      payload.method = apiInput.method
      payload.body_type = bodyType
      payload.requests.headers = arrayToObject(headers)
      payload.requests.params = arrayToObject(params)
      if (bodyType === "json") {
        payload.requests.body = codeInput
      } else if (bodyType === "form") {
        payload.requests.body = arrayToObject(bodyInputs)
      }
      onSubmit(payload)
      setError(false)
    }
  }

  useEffect(() => {
    let isEmpty = Object.values(apiInput).some((value) => value === "") || !bodyType

    if (bodyType === "json") {
      isEmpty = codeInput === ""
    }
    setIsDisabled(isEmpty)
  }, [apiInput, bodyType, codeInput])

  useEffect(() => {
    if (formData.id) {
      setApiInput({
        method: formData.method,
        url: formData.url,
      })
      setBodyType(formData.body_type)
      if (formData.params.requests?.headers) {
        setHeaders(objectToArray(formData.params.requests.headers))
      }
      if (formData.params.requests?.params) {
        setParams(objectToArray(formData.params.requests.params))
      }
      if (typeof formData.params.requests?.body === "object") {
        if (formData.body_type === "json") {
          setCodeInput(JSON.stringify(formData.params.requests.body))
        } else if (formData.body_type === "form") {
          setBodyInputs(objectToArray(formData.params.requests.body))
        }
      }
    }
  }, [formData])

  return (
    <>
      <Button
        sx={{ position: "absolute", top: "1.25rem", right: "1.5rem", zIndex: 2 }}
        onClick={handleOnSubmit}
        variant="contained"
        disabled={isLoadingForm || isDisabled}
      >
        Save
      </Button>
      <section style={{ position: "relative" }}>
        <LocalLoadingBar localLoading={isLoadingForm} />
      </section>
      <div className={ActionDrawerStyles.main}>
        <div className={ActionDrawerStyles.requestContainer}>
          <h4>API</h4>
          <div className={ActionDrawerStyles.column}>
            <SelectField
              options={REQUEST_METHODS}
              value={apiInput.method}
              onChange={(event) => {
                setApiInput((prevValue) => ({ ...prevValue, method: event.target.value }))
              }}
            />
            <MixedTextArea
              chipList={availableVars}
              initialValue={apiInput.url}
              onBlur={(value) => {
                setApiInput((prevValue) => ({ ...prevValue, url: value }))
              }}
              mLength={200}
              placeholder="Enter the API URL"
              hideEndAdornment
              hideVariables
            />
          </div>
        </div>
        <div className={ActionDrawerStyles.requestContainer}>
          <h4>Headers</h4>
          {headers.map((header, index) => (
            <ParamColumn
              key={index}
              availableVars={availableVars}
              column={header}
              index={index}
              onKeyChange={(index, key) => {
                variableAction.edit(setHeaders, index, { key })
              }}
              onValueChange={(index, value) => variableAction.edit(setHeaders, index, { value })}
              onDelete={variableAction.remove(setHeaders)}
            />
          ))}
          <Button onClick={variableAction.add(setHeaders)} className={ActionDrawerStyles.add}>
            + Add Header
          </Button>
        </div>
        <div className={ActionDrawerStyles.requestContainer}>
          <h4>Params</h4>
          {params.map((param, index) => (
            <ParamColumn
              key={index}
              availableVars={availableVars}
              column={param}
              index={index}
              onKeyChange={(index, key) => variableAction.edit(setParams, index, { key })}
              onValueChange={(index, value) => variableAction.edit(setParams, index, { value })}
              onDelete={variableAction.remove(setParams)}
            />
          ))}
          <Button onClick={variableAction.add(setParams)} className={ActionDrawerStyles.add}>
            + Add Param
          </Button>
        </div>
        <div className={ActionDrawerStyles.requestContainer}>
          <h4>Body</h4>
          <div style={{ marginBottom: "1rem" }}>
            <SelectField
              options={BODY_TYPES}
              value={bodyType}
              onChange={(event) => setBodyType(event.target.value)}
              disabled={isLoadingForm}
            />
          </div>
          <div>
            {bodyType === "json" ? (
              <textarea
                value={codeInput}
                className={ActionDrawerStyles.codeContainer}
                onChange={(event) => setCodeInput(event.target.value)}
                disabled={isLoadingForm}
                style={{ height: 160 }}
              />
            ) : bodyType === "form" ? (
              <>
                {bodyInputs.map((input, index) => (
                  <ParamColumn
                    key={index}
                    availableVars={availableVars}
                    column={input}
                    index={index}
                    onKeyChange={(index, key) => variableAction.edit(setBodyInputs, index, { key })}
                    onValueChange={(index, value) => variableAction.edit(setBodyInputs, index, { value })}
                    onDelete={variableAction.remove(setBodyInputs)}
                  />
                ))}
                <Button onClick={variableAction.add(setBodyInputs)} className={ActionDrawerStyles.add}>
                  + Add Payload
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <div className={ActionDrawerStyles.requestContainer}>
          <h4>
            Output Variable
            <BasicTooltip
              tooltip={`The result generated from this action can be referred as the following variable in any future actions.`}
            />
          </h4>
          <p>{`action_${sequence}_output`}</p>
        </div>
      </div>
    </>
  )
}

export default HttpRequestDrawer
