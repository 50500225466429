import request from "../axios"

const requests = {
  createProject: (payload) => {
    return request.post("/app", payload)
  },
  removeProject: (appId) => {
    return request.delete(`/app/${appId}`)
  },
  getProject: (appId) => {
    return request.get(`/app/${appId}`)
  },
  updateProject: (appId, payload) => {
    return request.put(`/app/${appId}`, payload)
  },
  getFlow: (versionId) => {
    return request.get(`/appversion/${versionId}`)
  },
  updateFlow: (versionId, payload) => {
    return request.put(`/appversion/${versionId}`, payload)
  },
  getComponent: (componentId) => {
    return request.get(`/component/${componentId}`)
  },
  updateComponent: (componentId, payload) => {
    return request.put(`/component/${componentId}`, payload)
  },
  createConfig: (payload) => {
    return request.post("/config", payload)
  },
  updateConfig: (configId, payload) => {
    return request.put(`/config/${configId}`, payload)
  },
  updateOutput: (outputId, payload) => {
    return request.put(`/output/${outputId}`, payload)
  },
  getLastestProjectId: async () => {
    try {
      const { data } = await request.get("/app_list")

      if (data.results && data.results.length) {
        return data.results[0].id
      }
      return ""
    } catch (error) {
      return ""
    }
  },
  getAllProjects: async () => {
    let page = 1
    let apps = []

    try {
      const { data } = await request.get(`/app_list?page=${page}`)
      const pages = Math.ceil(data.count / 10)

      apps = apps.concat(data.results)
      while (page < pages) {
        page += 1
        const { data } = await request.get(`/app_list?page=${page}`)
        apps = apps.concat(data.results)
      }

      return apps
    } catch (error) {
      return apps
    }
  },
  getProjectList: (params) => {
    return request.get("/app_list", { params })
  },
  getFunctionList: (params) => {
    return request.get("/afunction_list", { params })
  },
  createSmartFunctionList: (payload) => {
    return request.post("/smart_function", payload)
  },
  getSmartFunctionList: (params) => {
    return request.get("/smart_function_list", { params })
  },
  copyProject: (appId) => {
    return request.post(`/app/${appId}/clone`)
  },
  createTemplate: (appId) => {
    return request.post(`/app/${appId}/clone`, null, { params: { is_demo: true } })
  },
  getTemplateList: () => {
    return request.get("/app_list", { params: { is_demo: true } })
  },
  createVersion: (versionId) => {
    return request.post(`/publish_version/${versionId}`, {})
  },
  createCode: (payload) => {
    return request.post("/code", payload)
  },
  updateCode: (codeId, payload) => {
    return request.put(`/code/${codeId}`, payload)
  },
  createHttpRequest: (payload) => {
    return request.post("/requests", payload)
  },
  updateHttpRequest: (requestId, payload) => {
    return request.put(`/requests/${requestId}`, payload)
  },
}

export default requests
